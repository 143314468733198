import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import useApi from "../../hooks/useApi";

import { jwtDecode } from "jwt-decode";

import { weekDays } from "../../store/GlobalVarriables";
import ScheduleInviteInput from "../../Components/ScheduleInviteInput/ScheduleInviteInput";

import CustomSelect from "../../Components/CustomSelect/CustomSelect";
import ModalConfirmation from "../../Components/ModalConfirmation/ModalConfirmation";
import ModalWarning from "../../Components/ModalWarning/ModalWarning";

import { ReactComponent as Tick } from "../../static/images/tick_sign.svg";

import "./style.scss";

const ScheduleCreateInvite = () => {
  const { post, get, loading } = useApi();

  const navigate = useNavigate();
  const timeInputRef = useRef(null);
  const { t } = useTranslation();

  const [scheudleId, setScheduleId] = useState("");

  const [confirmModalToggle, setConfirmModalToggle] = useState("");
  const [confirmModalText, setConfirmModalText] = useState("");

  const [warningModalToggle, setWarningModalToggle] = useState("");
  const [warningModalText, setWarningModalText] = useState("");

  // shcedule

  const [groupType, setGroupType] = useState("individual");
  const [maxMembers, setMaxMembers] = useState("");
  const [scheduleName, setScheduleName] = useState("");
  const [startDate, setStartDate] = useState("");

  const [eventDate, setEventDate] = useState("");
  const [eventTime, setEventTime] = useState("");

  const [duration, setDuration] = useState("");
  const [cancelSchedule, setCancelSchedule] = useState("no");
  const [cancelationGrace, setCancelationGrace] = useState("");

  // financial

  const [whoPays, setWhoPays] = useState("0");
  const [priceType, setPriceType] = useState("1");
  const [price, setPrice] = useState("");
  const [billingPeriod, setBillingPeriod] = useState("0");
  const [ofSessionBilling, setOfSessionBilling] = useState("");
  const [paymentType, setPaymentType] = useState("0");
  const [ofSessionPrePay, setOfSessionPrePay] = useState("");

  // location details
  const [city, setCity] = useState("");
  const [district, setDistrict] = useState("");
  const [address, setAddress] = useState("");

  // customer details

  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");

  // labels

  const [maxMembersLabel, setMaxMembersLabel] = useState(
    t("input_label_max_number"),
  );
  const [scheduleNameLabel, setScheduleNameLabel] = useState(
    t("input_label_schedule_name"),
  );
  const [startDateLabel, setStartDateLabel] = useState(
    t("input_label_start_date"),
  );
  const [priceLabel, setPriceLabel] = useState(t("input_label_price"));
  const [durationLabel, setDurationLabel] = useState(
    t("input_label_event_duration"),
  );
  const [cancelationGraceLabel, setCancelationGraceLabel] = useState(
    t("input_label_cancelation_grace"),
  );

  const [ofSessionBillingLabel, setOfSessionBillingLabel] = useState(
    t("input_label_of_sessions_billing"),
  );
  const [ofSessionPrePayLabel, setOfSessionPrePayLabel] = useState(
    t("input_label_of_sessions_prepayment"),
  );

  const [eventDateLable, setEventDateLabel] = useState(
    t("input_label_event_date"),
  );
  const [eventTimeLabel, setEventTimeLabel] = useState(
    t("input_label_event_time"),
  );

  const [cityLabel, setCityLabel] = useState(t("input_label_city"));
  const [districtLabel, setDistrictLabel] = useState(t("input_label_district"));
  const [addressLabel, setAddressLabel] = useState(t("input_label_address"));

  const [mobileLabel, setMobileLabel] = useState(
    t("input_label_customer_mobile"),
  );
  const [emailLabel, setEmailLabel] = useState(t("input_label_customer_email"));

  // const [scheduleDays, setScheduleDays] = useState([]);
  const [selectedDayTime, setSelectedDayTime] = useState({});
  const [currentDay, setCurrentDay] = useState(null);
  const [timePickerVisible, setTimePickerVisible] = useState(false);
  const [dateHasError, setDateHasError] = useState(false);

  const changeLabelHandler = (lable, value) => {
    let numberValidation = /^[1-9]\d*(\.\d+)?$|^0(\.\d+)?$/;
    let emailValidation = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (lable === "date") {
      if (value) {
        setStartDateLabel(t("input_label_start_date"));
      }
    }
    if (lable === "event-date") {
      if (value) {
        setEventDateLabel(t("input_label_event_date"));
      }
    }
    if (lable === "event-time") {
      if (value) {
        setEventTimeLabel(t("input_label_event_time"));
      }
    }

    if (groupType === "group") {
      if (lable === "member") {
        if (value.length === 0) {
          setMaxMembersLabel(t("error_max_member_required"));
        } else if (value[0] === "0") {
          setMaxMembersLabel(t("error_start_with_zero"));
        } else if (value.includes(",") || value.includes(".")) {
          setMaxMembersLabel(t("error_is_not_whole"));
        } else if (!numberValidation.test(value)) {
          setMaxMembersLabel(t("error_is_not_number"));
        } else {
          setMaxMembersLabel(t("input_label_max_number"));
        }
      }
    }

    if (lable === "name") {
      if (value.length === 0) {
        setScheduleNameLabel(t("error_schedule_name_required"));
      } else {
        setScheduleNameLabel(t("input_label_schedule_name"));
      }
    }

    if (lable === "price") {
      if (value.length === 0) {
        setPriceLabel(t("error_price_required"));
      } else if (!numberValidation.test(value)) {
        setPriceLabel(t("error_is_not_number"));
      } else {
        setPriceLabel(t("input_label_price"));
      }
    }
    if (lable === "duration") {
      if (value.length === 0) {
        setDurationLabel(t("error_event_duration_required"));
      } else if (value[0] === "0") {
        setDurationLabel(t("error_start_with_zero"));
      } else if (!numberValidation.test(value)) {
        setDurationLabel(t("error_is_not_number"));
      } else {
        setDurationLabel(t("input_label_event_duration"));
      }
    }
    if (lable === "cancelation-grace") {
      if (value.length === 0) {
        setCancelationGraceLabel(t("error_cancelation_grace_required"));
      } else if (value[0] === "0") {
        setCancelationGraceLabel(t("error_start_with_zero"));
      } else if (value.includes(",") || value.includes(".")) {
        setCancelationGraceLabel(t("error_is_not_whole"));
      } else if (!numberValidation.test(value)) {
        setCancelationGraceLabel(t("error_is_not_number"));
      } else {
        setCancelationGraceLabel(t("input_label_cancelation_grace"));
      }
    }

    if (lable === "of-session-billing") {
      if (value.length === 0) {
        setOfSessionBillingLabel(t("error_of_seesion_billing_required"));
      } else if (!numberValidation.test(value)) {
        setOfSessionBillingLabel(t("error_is_not_number"));
      } else {
        setOfSessionBillingLabel(t("input_label_of_sessions_billing"));
      }
    }
    if (lable === "of-session-prePay") {
      if (value.length === 0) {
        setOfSessionPrePayLabel(t("error_of_session_prepaid_required"));
      } else if (!numberValidation.test(value)) {
        setOfSessionPrePayLabel(t("error_is_not_number"));
      } else {
        setOfSessionPrePayLabel(t("input_label_of_sessions_prepayment"));
      }
    }
    if (lable === "city") {
      if (value.length === 0) {
        setCityLabel(t("error_city_required"));
      } else {
        setCityLabel(t("input_label_city"));
      }
    }
    if (lable === "district") {
      if (value.length === 0) {
        setDistrictLabel(t("error_district_required"));
      } else {
        setDistrictLabel(t("input_label_district"));
      }
    }
    if (lable === "address") {
      if (value.length === 0) {
        setAddressLabel(t("error_address_required"));
      } else {
        setAddressLabel(t("input_label_address"));
      }
    }

    if (lable === "customerNum") {
      if (value.length === 0) {
        setMobileLabel(t("error_cutomer_mobile_required"));
      } else if (!numberValidation.test(value)) {
        setMobileLabel(t("error_is_not_number"));
      } else if (value.length !== 9) {
        setMobileLabel(t("error_mobile_length"));
      } else {
        setMobileLabel(t("input_label_customer_mobile"));
      }
    }

    if (lable === "email") {
      if (value.length === 0) {
        setEmailLabel(t("error_email_required"));
      } else if (!emailValidation.test(String(value).toLowerCase())) {
        setEmailLabel(t("error_email_format"));
      } else {
        setEmailLabel(t("input_label_customer_email"));
      }
    }
  };

  const invitationButton = () => {
    if (groupType === "group" && maxMembers.length === 0) {
      setMaxMembersLabel(t("error_max_member_required"));
    }

    if (scheduleName === "") {
      setScheduleNameLabel(t("error_schedule_name_required"));
    } else {
      setScheduleNameLabel(t("input_label_schedule_name"));
    }
    if (price === "") {
      setPriceLabel(t("error_price_required"));
    }
    if (duration === "") {
      setDurationLabel(t("error_event_duration_required"));
    }

    if (!startDate) {
      setStartDateLabel(t("error_start_date_required"));
    }

    if (Object.entries(selectedDayTime).length === 0) {
      setDateHasError(true);
    }

    if (city === "") {
      setCityLabel(t("error_city_required"));
    }
    if (district === "") {
      setDistrictLabel(t("error_district_required"));
    }
    if (address === "") {
      setAddressLabel(t("error_address_required"));
    }

    if (cancelSchedule === "yes") {
      if (cancelationGrace === "") {
        setCancelationGraceLabel("cancelation grace hours required!");
        return;
      }
    }

    if (ofSessionBilling === "") {
      setOfSessionBillingLabel(t("error_of_seesion_billing_required"));
      return;
    }

    if (ofSessionPrePay === "") {
      setOfSessionPrePayLabel(t("error_of_session_prepaid_required"));
      return;
    }

    if (!eventDate) {
      setEventDateLabel(t("error_event_date_required"));
    }
    if (!eventTime) {
      setEventTimeLabel(t("error_event_time_required"));
    }

    if (groupType === "individual") {
      if (mobile === "" && email === "") {
        setMobileLabel(t("error_email_or_mobile_required"));
        setEmailLabel(t("error_email_or_mobile_required"));
        return;
      }
      if (
        (emailLabel.includes("!") && email.length > 0) ||
        (mobileLabel.includes("!") && mobile.length > 0)
      ) {
        return;
      }

      if (
        scheduleName.length !== 0 &&
        !scheduleNameLabel.includes("!") &&
        price.length !== 0 &&
        !priceLabel.includes("!") &&
        duration.length !== 0 &&
        !duration.includes("!") &&
        !cancelationGraceLabel.includes("!") &&
        startDate.length &&
        !startDateLabel.includes("!") &&
        city.length !== 0 &&
        !cityLabel.includes("!") &&
        district.length !== 0 &&
        !districtLabel.includes("!") &&
        address.length !== 0 &&
        !addressLabel.includes("!") &&
        !ofSessionBillingLabel.includes("!") &&
        !ofSessionPrePayLabel.includes("!")
      ) {
        setMobileLabel(t("input_label_customer_mobile"));
        setEmailLabel(t("input_label_customer_email"));

        setConfirmModalToggle("modal-confirm");
        setConfirmModalText(t("create_invitation_confirmation_text"));
      }
    } else if (groupType === "group") {
      if (
        scheduleName.length !== 0 &&
        !scheduleNameLabel.includes("!") &&
        price.length !== 0 &&
        !priceLabel.includes("!") &&
        duration.length !== 0 &&
        !durationLabel.includes("!") &&
        !cancelationGraceLabel.includes("!") &&
        startDate.length &&
        maxMembers.length !== 0 &&
        !maxMembersLabel.includes("!") &&
        city.length !== 0 &&
        !cityLabel.includes("!") &&
        district.length !== 0 &&
        !districtLabel.includes("!") &&
        address.length !== 0 &&
        !addressLabel.includes("!") &&
        !ofSessionBillingLabel.includes("!") &&
        !ofSessionPrePayLabel.includes("!")
      ) {
        setConfirmModalToggle("modal-confirm");
        setConfirmModalText(t("create_group_invitation_confirmation_text"));
      }
    } else if (groupType === "one-time") {
      if (mobile === "" && email === "") {
        setMobileLabel(t("error_email_or_mobile_required"));
        setEmailLabel(t("error_email_or_mobile_required"));
        return;
      }

      if (
        (emailLabel.includes("!") && email.length > 0) ||
        (mobileLabel.includes("!") && mobile.length > 0)
      ) {
        return;
      }

      if (
        scheduleName.length !== 0 &&
        !scheduleNameLabel.includes("!") &&
        price.length !== 0 &&
        !priceLabel.includes("!") &&
        duration.length !== 0 &&
        !durationLabel.includes("!") &&
        !cancelationGraceLabel.includes("!") &&
        eventDate.length !== 0 &&
        !eventDateLable.includes("!") &&
        eventTime.length !== 0 &&
        !eventTimeLabel.includes("!") &&
        city.length !== 0 &&
        !cityLabel.includes("!") &&
        district.length !== 0 &&
        !districtLabel.includes("!") &&
        address.length !== 0 &&
        !addressLabel.includes("!")
      ) {
        {
          setMobileLabel(t("input_label_customer_mobile"));
          setEmailLabel(t("input_label_customer_email"));

          setConfirmModalToggle("modal-confirm");
          setConfirmModalText(t("create_onetime_invitation_confirmation_text"));
        }
      }
    }
  };

  const postInvitation = async () => {
    const ps10005000 = JSON.parse(localStorage.getItem("ps10005000"));
    const encodedToken = jwtDecode(ps10005000);

    const oneTimeData = {
      businessAccountId: encodedToken.buid,
      effectiveFrom: eventDate + "T12:" + `${eventTime}.00` + "Z",
      description: scheduleName,
      duration: +duration,
      eventTime: eventDate + "T12:" + `${eventTime}.00` + "Z",
      price: +price,
      cancellationGraceHours: cancelSchedule === "yes" ? +cancelationGrace : 0,
    };

    const commonScheduleData = {
      businessAccountId: encodedToken.buid,
      effectiveFrom: startDate,
      description: scheduleName,
      groupSchedule: groupType === "group" ? true : false,
      isOneTime: false,
      maxMembers: groupType === "group" ? +maxMembers : 1,
      duration: +duration,
      cancellationGraceHours: cancelSchedule === "yes" ? +cancelationGrace : 0,
      price: +price,
      scheduleDays: Object.entries(selectedDayTime).map(
        ([dayOfWeek, timeOnly]) => ({ timeOnly, dayOfWeek }),
      ),
    };

    const commonFinancialData = {
      payerOption: groupType === "group" ? +whoPays : 0,
      pricingType: +priceType,
      billingPeriod: +billingPeriod,
      billingEventCount: billingPeriod === "0" ? +ofSessionBilling : null,
      paymentType:
        billingPeriod === "1"
          ? 1
          : billingPeriod === "2"
            ? 0
            : billingPeriod === "0"
              ? +paymentType
              : null,
      prepaymentEventCount:
        paymentType === "0" && billingPeriod === "0" ? +ofSessionPrePay : null,
    };

    const commonAddressData = {
      city: city,
      district: district,
      address: address,
    };

    const commoninvitationData = {
      customerPhoneNumber: +mobile || null,
      customerEmailAddress: email || null,
    };

    const baseURL = {
      individual: "/Schedule/Invite",
      group: "/Schedule",
      oneTime: "/ApplicationEvent/oneTime ",
    };

    try {
      const response = await post(
        groupType === "group"
          ? baseURL.group
          : groupType === "individual"
            ? baseURL.individual
            : baseURL.oneTime,

        {
          ...(groupType === "individual" && {
            schedule: {
              ...commonScheduleData,
              financialDetails: { ...commonFinancialData },
              location: { ...commonAddressData },
              customerCanCancel: cancelSchedule === "yes" ? true : false,
            },
            invitation: { ...commoninvitationData },
          }),
          ...(groupType === "group" && {
            ...commonScheduleData,
            financialDetails: { ...commonFinancialData },
            location: { ...commonAddressData },
            customerCanCancel: cancelSchedule === "yes" ? true : false,
          }),
          ...(groupType === "one-time" && {
            schedule: {
              ...oneTimeData,
              ...commonAddressData,
            },
            invitation: { ...commoninvitationData },
          }),
        },
      );

      setScheduleId(response);
      setWarningModalToggle("modal-warning");
      setWarningModalText(
        groupType === "group"
          ? t("schedule_crete_success")
          : t("invitation_sent_success"),
      );
    } catch (err) {}
  };

  const handleSelectDay = (day) => {
    setSelectedDayTime((prevTimes) => {
      if (Object.keys(prevTimes).includes(day)) {
        setTimePickerVisible(false);
      } else {
        setCurrentDay(day);
        setTimePickerVisible(true);
      }
      const updatedTimes = { ...prevTimes };
      delete updatedTimes[day];
      return updatedTimes;
    });
  };

  const handleTimeChange = (event) => {
    const time = event.target.value;
    setSelectedDayTime((prevTimes) => ({
      ...prevTimes,
      [currentDay]: `${time}:00`,
    }));
    setDateHasError(false);
  };

  const fetchAddress = async () => {
    const ps10005000 = JSON.parse(localStorage.getItem("ps10005000"));

    const decodedToken = jwtDecode(ps10005000);

    try {
      const data = await get(`/BusinessAccount/${decodedToken.buid}`);

      setCity(data.city);
      setDistrict(data.district);
      setAddress(data.address);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (timePickerVisible && timeInputRef.current) {
      timeInputRef.current.focus();
      timeInputRef.current.click();
    }
  }, [timePickerVisible]);

  useEffect(() => {
    fetchAddress();
  }, []);

  return (
    <div className="invite-container">
      <ModalConfirmation
        modalText={t("create_invitation_confirm_text")}
        modalSubText={confirmModalText}
        changeModal={setConfirmModalToggle}
        modalValue={confirmModalToggle}
        confirm={postInvitation}
      />

      <ModalWarning
        warningText={warningModalText}
        buttonText={
          warningModalText.includes("successfully") && groupType === "one-time"
            ? t("go_main_page")
            : (warningModalText.includes("successfully") &&
                  groupType === "individual") ||
                groupType === "group"
              ? t("go_schedule_details")
              : t("close")
        }
        changeModal={setWarningModalToggle}
        modalValue={warningModalToggle}
        Image={Tick}
        handleButton={() => {
          navigate(`/schedule/${scheudleId}`);
        }}
      />

      <div className="create-invitatiion-header">
        <span>{t("create_invitation_header")}</span>
      </div>

      <div className="form-container">
        <form>
          <div className="form-section-header">
            <div>
              <span>{t("create_invitation_details")}</span>
            </div>
          </div>
          <div
            className="form-section-divider"
            style={{
              marginTop: "40px",
            }}
          >
            <CustomSelect
              options={[
                {
                  label: t("create_invitation_type_individual"),
                  value: "individual",
                },
                { label: t("create_invitation_type_group"), value: "group" },
                {
                  label: t("create_invitation_type_oneTime"),
                  value: "one-time",
                },
              ]}
              label={t("select_label_group_type")}
              onSelect={setGroupType}
              optionAction={(value) => {
                if (value === "one-time") {
                  setOfSessionBillingLabel("");
                  setOfSessionPrePayLabel("");
                  setOfSessionBilling(null);
                  setOfSessionPrePay(null);
                } else {
                  setOfSessionBillingLabel(
                    t("input_label_of_sessions_billing"),
                  );
                  setOfSessionPrePayLabel(
                    t("input_label_of_sessions_prepayment"),
                  );
                  setOfSessionBilling("");
                  setOfSessionPrePay("");

                  setBillingPeriod("0");
                }
              }}
            />

            <ScheduleInviteInput
              name={"name"}
              label={scheduleNameLabel}
              changeValue={setScheduleName}
              autoComplete={"name"}
              changeLableValue={changeLabelHandler}
              value={scheduleName}
            />

            {groupType === "group" ? (
              <ScheduleInviteInput
                name={"member"}
                label={maxMembersLabel}
                changeValue={setMaxMembers}
                changeLableValue={changeLabelHandler}
                value={maxMembers}
              />
            ) : null}

            {groupType === "group" || groupType === "individual" ? (
              <>
                <div className="input-date">
                  <input
                    id="date"
                    type="date"
                    onChange={(e) => {
                      changeLabelHandler("date", e.target.value);
                      setStartDate(e.target.value);
                    }}
                  />
                  <label
                    htmlFor="date"
                    style={
                      startDateLabel.includes("!") ||
                      startDateLabel.includes("required")
                        ? { color: "red" }
                        : { color: "rgb(143, 142, 142)" }
                    }
                  >
                    {startDateLabel}
                  </label>
                </div>
                <div className="days-selection">
                  <div className="details-days">
                    {weekDays.map((day, index) => (
                      <div
                        key={index}
                        onClick={() => handleSelectDay(day)}
                        className={
                          Object.keys(selectedDayTime).includes(day)
                            ? "active"
                            : null
                        }
                      >
                        {day.slice(0, 3)}
                        {selectedDayTime[day] && (
                          <span>{selectedDayTime[day].slice(0, 5)}</span>
                        )}
                      </div>
                    ))}
                  </div>
                  {timePickerVisible && (
                    <div className="time-picker-modal">
                      <input
                        type="time"
                        onChange={handleTimeChange}
                        value={selectedDayTime[currentDay] || ""}
                        ref={timeInputRef}
                        onBlur={(e) => {
                          setTimePickerVisible(false);
                        }}
                      />
                    </div>
                  )}

                  <span
                    className={
                      dateHasError ? "dateSelectionError" : "dateSelectionLabel"
                    }
                  >
                    {t(
                      dateHasError
                        ? "error_days_and_time_required"
                        : "select_label_days",
                    )}
                  </span>
                </div>
              </>
            ) : null}

            {groupType === "one-time" ? (
              <>
                <div className="input-date">
                  <input
                    id="one-time-date"
                    type="date"
                    value={eventDate}
                    onChange={(e) => {
                      changeLabelHandler("event-date", e.target.value);
                      setEventDate(e.target.value);
                    }}
                  />
                  <label
                    htmlFor="one-time-date"
                    style={
                      eventDateLable.includes("!") ||
                      eventDateLable.includes("required")
                        ? { color: "red" }
                        : { color: "rgb(143, 142, 142)" }
                    }
                  >
                    {eventDateLable}
                  </label>
                </div>

                <div className="input-date">
                  <input
                    id="one-time-houres"
                    type="time"
                    value={eventTime}
                    onChange={(e) => {
                      changeLabelHandler("event-time", e.target.value);
                      setEventTime(e.target.value);
                    }}
                  />
                  <label
                    htmlFor="one-time-houres"
                    style={
                      eventTimeLabel.includes("!") ||
                      eventTimeLabel.includes("required")
                        ? { color: "red" }
                        : { color: "rgb(143, 142, 142)" }
                    }
                  >
                    {eventTimeLabel}
                  </label>
                </div>
              </>
            ) : null}

            <ScheduleInviteInput
              name="duration"
              label={durationLabel}
              changeValue={setDuration}
              changeLableValue={changeLabelHandler}
              value={duration}
            />

            <CustomSelect
              // disabled={groupType === "group" ? true : false}
              options={[
                { label: t("no"), value: "no" },
                {
                  label: t("yes"),
                  value: "yes",
                },
              ]}
              label={t("select_label_customer_can_cancel")}
              onSelect={setCancelSchedule}
              optionAction={(value) => {
                if (value === "no") {
                  setCancelationGraceLabel("");
                  setCancelationGrace(null);
                } else {
                  setCancelationGraceLabel(t("input_label_cancelation_grace"));
                  setCancelationGrace("");
                }
              }}
            />

            {cancelSchedule === "yes" ? (
              <ScheduleInviteInput
                name={"cancelation-grace"}
                label={cancelationGraceLabel}
                changeValue={setCancelationGrace}
                changeLableValue={changeLabelHandler}
                value={cancelationGrace}
              />
            ) : null}
          </div>

          <div className="form-section-header">
            <div>
              <span>{t("financial_section_header")}</span>
            </div>
          </div>
          <div className="form-section-divider">
            {groupType == "group" ? (
              <CustomSelect
                options={[
                  {
                    label: t("create_invitation_payment_method_individually"),
                    value: "0",
                  },
                  {
                    label: t("create_invitation_payment_method_group"),
                    value: "1",
                  },
                ]}
                label={t("select_label_who_pays")}
                onSelect={setWhoPays}
              />
            ) : null}

            {groupType === "group" || groupType == "individual" ? (
              <CustomSelect
                options={[
                  {
                    label: t("create_invitation_pricing_type_perSession"),
                    value: "1",
                  },
                  {
                    label: t("create_invitation_pricing_type_monthly"),
                    value: "0",
                  },
                ]}
                label={t("select_label_pricing_type")}
                onSelect={setPriceType}
              />
            ) : null}

            <ScheduleInviteInput
              name={"price"}
              label={priceLabel}
              changeValue={setPrice}
              changeLableValue={changeLabelHandler}
              value={price}
            />

            {groupType === "group" || groupType == "individual" ? (
              <CustomSelect
                options={[
                  {
                    label: t("create_invitation_billing_based_on_events"),
                    value: "0",
                  },
                  {
                    label: t("create_invitation_billing_month_end"),
                    value: "1",
                  },
                  {
                    label: t("create_invitation_billing_month_start"),
                    value: "2",
                  },
                ]}
                label={t("select_label_billing_period")}
                onSelect={setBillingPeriod}
                optionAction={(value) => {
                  if (value === "0") {
                    setOfSessionBillingLabel(
                      t("input_label_of_sessions_billing"),
                    );
                    setOfSessionPrePayLabel(
                      t("input_label_of_sessions_prepayment"),
                    );
                    setOfSessionBilling("");
                    setOfSessionPrePay("");
                  } else {
                    setOfSessionBillingLabel("");
                    setOfSessionPrePayLabel("");
                    setOfSessionBilling(null);
                    setOfSessionPrePay(null);
                  }
                }}
              />
            ) : null}
          </div>

          {(groupType === "individual" || groupType === "group") &&
          billingPeriod == "0" ? (
            <>
              <ScheduleInviteInput
                name={"of-session-billing"}
                label={ofSessionBillingLabel}
                value={ofSessionBilling}
                changeValue={setOfSessionBilling}
                changeLableValue={changeLabelHandler}
              />

              <CustomSelect
                options={[
                  {
                    label: t("create_invitation_payment_type_prepaid"),
                    value: "0",
                  },
                  {
                    label: t("create_invitation_payment_type_postpaid"),
                    value: "1",
                  },
                ]}
                label={t("select_label_payment_type")}
                onSelect={setPaymentType}
                optionAction={(value) => {
                  if (value !== "0") {
                    setOfSessionPrePayLabel("");
                    setOfSessionPrePay(null);
                  } else {
                    setOfSessionPrePayLabel(
                      t("input_label_of_sessions_prepayment"),
                    );
                    setOfSessionPrePay("");
                  }
                }}
              />

              {paymentType === "0" ? (
                <ScheduleInviteInput
                  name={"of-session-prePay"}
                  label={ofSessionPrePayLabel}
                  changeValue={setOfSessionPrePay}
                  value={ofSessionPrePay}
                  changeLableValue={changeLabelHandler}
                />
              ) : null}
            </>
          ) : null}

          <div className="form-section-header">
            <div>
              <span>{t("location_section_header")}</span>
            </div>
          </div>
          <div className="form-section-divider">
            <ScheduleInviteInput
              name={"city"}
              label={cityLabel}
              value={city}
              changeValue={setCity}
              changeLableValue={changeLabelHandler}
            />
            <ScheduleInviteInput
              name={"district"}
              label={districtLabel}
              value={district}
              changeValue={setDistrict}
              changeLableValue={changeLabelHandler}
            />
            <ScheduleInviteInput
              name={"address"}
              label={addressLabel}
              value={address}
              changeValue={setAddress}
              changeLableValue={changeLabelHandler}
            />
          </div>

          {groupType === "individual" || groupType === "one-time" ? (
            <>
              <div className="form-section-header">
                <div>
                  <span>{t("customer_section_header")}</span>
                </div>
              </div>
              <div className="form-section-divider">
                <>
                  <ScheduleInviteInput
                    name={"customerNum"}
                    label={mobileLabel}
                    changeValue={setMobile}
                    autoComplete={"tel"}
                    changeLableValue={changeLabelHandler}
                    value={mobile}
                  />

                  <div
                    style={{
                      textAlign: "center",
                      paddingBottom: "25px",
                      color: "#A1A9BC",
                    }}
                  >
                    <span>{t("or")}</span>
                  </div>

                  <ScheduleInviteInput
                    name={"email"}
                    label={emailLabel}
                    changeValue={setEmail}
                    autoComplete={"email"}
                    changeLableValue={changeLabelHandler}
                    value={email}
                  />
                </>
              </div>
            </>
          ) : null}
        </form>
      </div>
      <div className="invite-button-container">
        <button id="add-schedule" onClick={invitationButton}>
          {t(groupType === "group" ? "add_schedule" : "invite_cistomer")}
        </button>
        <button
          id="cancel"
          onClick={() => {
            navigate("/sessions");
          }}
        >
          {t("cancel")}
        </button>
      </div>
      {loading && (
        <div className="loader-container">
          <div className="loader"></div>
        </div>
      )}
    </div>
  );
};

export default ScheduleCreateInvite;
