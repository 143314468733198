import { useEffect, useState } from "react";
import { jwtDecode } from "jwt-decode";
import { format, parse } from "date-fns";
import { useTranslation } from "react-i18next";

import useApi from "../../hooks/useApi";

import ScrollableItems from "../../Components/ScrollableItems";
import PhoneNumberToDial from "../../Components/PhoneNumberToDial";

import { generateUniqueId } from "../../store/GlobalVarriables";

import { ReactComponent as Passed } from "../../static/images/passed_history.svg";
import { ReactComponent as Canceled } from "../../static/images/canceled_history.svg";
import { ReactComponent as Single } from "../../static/images/training_single_schedule.svg";
import { ReactComponent as Group } from "../../static/images/training_group_schedule.svg";

import deleteIcon from "../../static/images/delete.png";

import { getLast12Months } from "../../helpers/days";

import "./style.scss";

const ProviderHistory = () => {
  const { get, loading } = useApi();
  const { t } = useTranslation();

  const [originalByScheduleData, setOriginalByScheduleData] = useState([]);
  const [byScheduleData, setByScheduleData] = useState([]);

  const [originalByCustomer, setOriginalByCustomer] = useState([]);
  const [byCustomer, setByCustomer] = useState([]);

  const [sortHistoryToggle, setSortHistoryToggle] = useState("byschedule");

  const [activeMonth, setActiveMonth] = useState(11);
  const [searchKey, setSearchKey] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const months = getLast12Months();
  const scrollItems = months.map((item) => ({
    ...item,
    mainText: t(item.monthName).slice(0, 3),
  }));

  const handleSelectMonth = (index, item, hasEvents) => {
    const dateFromItem = `${item} 01 00:00:00`;
    const parsedDate = parse(dateFromItem, "yyyy LLL dd HH:mm:ss", new Date());
    const formattedDate = format(parsedDate, "yyyy-MM-dd'T'HH:mm:ss");

    const newStarDate = new Date(formattedDate);
    let newLastDay = new Date(
      newStarDate.getFullYear(),
      newStarDate.getMonth() + 1,
      0,
    );
    setStartDate(format(newStarDate, "yyyy-MM-dd"));
    setEndDate(format(newLastDay, "yyyy-MM-dd"));
    setActiveMonth(index);
  };

  const getBySchedule = async (buid, startDate, endDate) => {
    if (startDate === "") {
      return;
    }

    try {
      const data = await get(
        `/BusinessAccount/${buid}/EventsHistoryBySchedule?IsOneTime=false&StarDateTime=${startDate}&EndDateTime=${endDate}`,
      );

      const initialData = data.map((item) => ({
        ...item,
        uniqueId: generateUniqueId(),
      }));

      setOriginalByScheduleData(initialData);
      if (searchKey.length >= 3) {
        setByScheduleData(
          initialData.filter((item) =>
            item.schedule.description
              .toLowerCase()
              .includes(searchKey.toLowerCase()),
          ),
        );
      } else {
        setByScheduleData(initialData);
      }
    } catch (err) {}
  };

  const getByCustomer = async (buid, startDate, endDate) => {
    try {
      const data = await get(
        `/BusinessAccount/${buid}/EventsHistoryByConsumer?IsOneTime=false&StarDateTime=${startDate}&EndDateTime=${endDate}`,
      );

      const initialData = data.map((item) => ({
        ...item,

        consumerDetails: item.providerDetails
          ? item.providerDetails
          : item.consumerDetails,

        providerDetails: undefined,

        uniqueId: generateUniqueId(),
      }));
      setOriginalByCustomer(initialData);
      if (searchKey.length >= 3) {
        setByCustomer(
          initialData.filter((item) =>
            item.consumerDetails.name
              .toLowerCase()
              .includes(searchKey.toLowerCase()),
          ),
        );
      } else {
        setByCustomer(initialData);
      }
    } catch (err) {}
  };

  const handleSearch = (value) => {
    setSearchKey(value);
    if (sortHistoryToggle === "byschedule") {
      if (value.length >= 3) {
        setByScheduleData(
          originalByScheduleData.filter((item) =>
            item.schedule.description
              .toLowerCase()
              .includes(value.toLowerCase()),
          ),
        );
      } else {
        setByScheduleData(originalByScheduleData);
      }
    } else if (sortHistoryToggle === "bycustomer") {
      if (value.length >= 3) {
        setByCustomer(
          originalByCustomer.filter((item) =>
            item.schedule.description
              .toLowerCase()
              .includes(value.toLowerCase()),
          ),
        );
      } else {
        setByCustomer(originalByCustomer);
      }
    }
  };

  const clearSearch = () => {
    setSearchKey("");
    setByCustomer(originalByCustomer);
    setByScheduleData(originalByScheduleData);
  };

  useEffect(() => {
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth() + 1;

    const formattedStartDate = `${year}-${month.toString().padStart(2, "0")}-01`;

    const lastDay = new Date(year, month, 0).getDate();
    const formattedEndDate = `${year}-${month.toString().padStart(2, "0")}-${lastDay}`;

    setStartDate(formattedStartDate);
    setEndDate(formattedEndDate);
  }, []);

  useEffect(() => {
    const ps10005000 = JSON.parse(localStorage.getItem("ps10005000"));
    const encodedToken = jwtDecode(ps10005000);

    if (sortHistoryToggle === "byschedule") {
      getBySchedule(encodedToken.buid, startDate, endDate);
    }
    if (sortHistoryToggle === "bycustomer") {
      getByCustomer(encodedToken.buid, startDate, endDate);
    }
  }, [startDate, endDate, sortHistoryToggle]);

  return (
    <div className="training-history-container">
      <ScrollableItems
        items={scrollItems}
        activeItem={activeMonth}
        selectItem={handleSelectMonth}
      />
      <div className="filters-wrapper">
        <div className="sort-history">
          <div
            className={`sort-byschedule-nav ${sortHistoryToggle}`}
            onClick={() => {
              setSortHistoryToggle("byschedule");
            }}
          >
            <span>{t("by_schedule")}</span>
          </div>
          <div
            className={`sort-bycustomer-nav ${sortHistoryToggle}`}
            onClick={() => {
              setSortHistoryToggle("bycustomer");
            }}
          >
            <span>{t("by_customer")}</span>
          </div>
        </div>
        <div className="history-search">
          <input
            type="text"
            placeholder={t("traning_history_search_placeholder")}
            onChange={(e) => {
              handleSearch(e.target.value);
            }}
            value={searchKey}
          />
          {searchKey.length >= 3 && (
            <button onClick={clearSearch}>
              <img src={deleteIcon} alt="clear" />
            </button>
          )}
        </div>
      </div>
      <div className="training-history-cards-container">
        {sortHistoryToggle === "byschedule" ? (
          <>
            {byScheduleData.length ? (
              <ul className="training-history-ul">
                {byScheduleData
                  .sort(
                    (a, b) =>
                      new Date(a.schedule.effectiveFrom) -
                      new Date(b.schedule.effectiveFrom),
                  )
                  .map((item) => {
                    return (
                      <li className="training-history-li" key={item.uniqueId}>
                        <div className="history-single-card">
                          <div className="history-description-container-byschedule">
                            <div className="history-description">
                              <div className="history-icon">
                                {item.schedule.groupSchedule === true ? (
                                  <Group />
                                ) : (
                                  <Single />
                                )}{" "}
                              </div>
                              <div className="history-description-text">
                                <span>{item.schedule.description}</span>
                              </div>
                            </div>
                            <div className="history-members">
                              <span>
                                {item.schedule.maxMembers -
                                  item.schedule.availableSeats}
                              </span>
                              <span>/{item.schedule.maxMembers}</span>
                            </div>
                          </div>

                          <div className="history-details-container">
                            <div className="session-logs-byschedule">
                              <div className="passed-sessions">
                                <div className="passed-sessions-icon">
                                  <Passed className="passed-sessions-icon" />
                                </div>
                                <span>
                                  {t(
                                    item.eventHistory.passed > 1
                                      ? "traning_history_passed_sessions_count"
                                      : "traning_history_passed_session",
                                    { count: item.eventHistory.passed },
                                  )}
                                </span>
                              </div>
                              <div className="passed-sessions">
                                <div className="passed-sessions-icon passed-sessions-icon-canceled">
                                  <Canceled className="passed-sessions-icon" />
                                </div>
                                <span>
                                  {t(
                                    item.eventHistory.cancelled > 1
                                      ? "traning_history_canceled_sessions_count"
                                      : "traning_history_canceled_session",
                                    { count: item.eventHistory.cancelled },
                                  )}
                                </span>
                              </div>
                            </div>
                            <div className="history-details-container-divider"></div>
                            <div className="show-details">
                              <button>{t("show_details")}</button>
                            </div>
                          </div>
                        </div>
                      </li>
                    );
                  })}
              </ul>
            ) : (
              <div className="no-historical-records">
                <span>{t("traning_history_no_records")}</span>
              </div>
            )}
          </>
        ) : null}

        {sortHistoryToggle === "bycustomer" ? (
          <>
            {byCustomer.length ? (
              <ul className="training-history-ul">
                {byCustomer
                  .sort((a, b) =>
                    a.consumerDetails.name.localeCompare(
                      b.consumerDetails.name,
                    ),
                  )
                  .map((item) => {
                    return (
                      <li className="training-history-li" key={item.uniqueId}>
                        <div className="history-single-card">
                          <div className="history-description-container-bycustomer">
                            <div className="history-account-info">
                              <div className="account-name">
                                <span>{item.consumerDetails.name}</span>
                              </div>
                              <div className="account-mobile">
                                <PhoneNumberToDial
                                  number={item.consumerDetails.phoneNumber}
                                />
                              </div>
                            </div>
                            <div className="history-description-container">
                              <div className="members-icon">
                                {item.schedule.groupSchedule === true ? (
                                  <Group />
                                ) : (
                                  <Single />
                                )}{" "}
                              </div>
                              <div className="description-text">
                                <span>{`${item.schedule.description}`}</span>
                              </div>
                            </div>
                            <div className="history-details-container">
                              <div className="session-logs-bycustomer">
                                <div className="passed-sessions">
                                  <Passed className="passed-sessions-icon" />
                                  <span>
                                    {t(
                                      item.eventHistory.passed > 1
                                        ? "traning_history_passed_sessions_count"
                                        : "traning_history_passed_session",
                                      { count: item.eventHistory.passed },
                                    )}
                                  </span>
                                </div>
                                <div className="passed-sessions">
                                  <Canceled className="passed-sessions-icon" />
                                  <span>
                                    {t(
                                      item.eventHistory.cancelled > 1
                                        ? "traning_history_canceled_sessions_count"
                                        : "traning_history_canceled_session",
                                      { count: item.eventHistory.cancelled },
                                    )}
                                  </span>
                                </div>
                              </div>
                              <div className="history-details-container-divider"></div>
                              <div className="show-details">
                                <button>{t("show_details")}</button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                    );
                  })}
              </ul>
            ) : (
              <div className="no-historical-records">
                <span>{t("traning_history_no_records")}</span>
              </div>
            )}
          </>
        ) : null}
      </div>

      {loading && (
        <div className="loader-container">
          <div className="loader"></div>
        </div>
      )}
    </div>
  );
};

export default ProviderHistory;
