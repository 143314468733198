import React from "react";

import { ReactComponent as DialIcon } from "../../static/images/dial-icon.svg";

import "./style.scss";

const PhoneNumberToDial = ({ number }) => {
  return (
    <a className="phoneNumber" href={`tel:${number}`}>
      <DialIcon />
      {number}
    </a>
  );
};

export default PhoneNumberToDial;
