import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { jwtDecode } from "jwt-decode";

import { useModalStore } from "../../store/GlobalVarriables";
import useApi from "../../hooks/useApi";
import { useStatusModal } from "../../context/StatusModalContext";

import DotsMenu from "../../Components/DotsMenu";
import ModalConfirmation from "../../Components/ModalConfirmation/ModalConfirmation";

import { ReactComponent as Single } from "../../static/images/single_schedule.svg";
import { ReactComponent as Group } from "../../static/images/group_schedule.svg";

import "./style.scss";

const ProviderGroups = () => {
  const { get, del, loading } = useApi();

  const [groupsSchedule, setGroupsSchedule] = useState([]);
  const [menuItemsParams, setMenuItemsParams] = useState({});

  const [invitationInputToggle, setInvitationInputToggle] = useModalStore("");

  const [confirmModalToggle, setConfirmModalToggle] = useState("");

  const navigate = useNavigate();
  const location = useLocation();
  const { showStatusModal } = useStatusModal();
  const { t } = useTranslation();

  const menuItems = [
    {
      text: t("schedule_details"),
      disabled: false,
      onClick: () => {
        navigate(`/schedule/${menuItemsParams.scheduleId}`);
      },
    },
    {
      text: t("edit_schedule"),
      onClick: () => {
        navigate(`/schedule/edit/${menuItemsParams.scheduleId}`);
      },
    },
    {
      text: t("invite_customer"),
      disabled: menuItemsParams.availableSeats <= 0,
      onClick: () => {
        if (menuItemsParams.availableSeats <= 0) {
          return;
        }
        handleMenuActions("invite customer");
      },
    },
    {
      text: t("cancel_schedule"),
      onClick: () => {
        handleMenuActions("cancel schedule");
      },
    },
    {
      text: t("finance"),
      disabled: true,
    },
    {
      text: t("training_history"),
      onClick: () => {
        navigate("/history");
      },
    },
  ];

  const getQueryParam = (key) => {
    const searchParams = new URLSearchParams(location.search);
    return searchParams.get(key);
  };

  const showFeatureTooltip = Boolean(getQueryParam("showFeatureTooltip"));
  useEffect(() => {
    if (showFeatureTooltip) {
      setMenuItemsParams({
        scheduleId: groupsSchedule[0]?.id,
        availableSeats: groupsSchedule[0]?.availableSeats,
        price: groupsSchedule[0]?.price,
        index: 0,
      });
      handleMenutoggle(0);
    } else {
      setMenuItemsParams({});
      handleMenutoggle(-1);
    }
  }, [showFeatureTooltip]);

  const fetchGroupSchedule = async () => {
    const ps10005000 = JSON.parse(localStorage.getItem("ps10005000"));
    const decodedToken = jwtDecode(ps10005000);

    try {
      const data = await get(
        `/BusinessAccount/${decodedToken.buid}/Schedules?oneTime=false&onlyWithSubscription=false`,
      );

      setGroupsSchedule(
        data.map((item) => ({
          ...item,
          showMenu: false,
        })),
      );
    } catch (err) {}
  };

  const handleMenutoggle = (index) => {
    setGroupsSchedule(
      groupsSchedule.map((item, i) =>
        i === index
          ? { ...item, showMenu: !item.showMenu }
          : { ...item, showMenu: false },
      ),
    );
  };

  const handleMenuActions = (identity) => {
    handleMenutoggle(menuItemsParams.index);
    if (identity === "cancel schedule") {
      setConfirmModalToggle("modal-confirm");
    } else {
      navigate(`/schedule/${menuItemsParams.scheduleId}`);
      setInvitationInputToggle("invite");
    }
  };

  const modalConfirmationBtn = async () => {
    try {
      const data = await del(`/Schedule/${menuItemsParams.scheduleId}/Cancel`);
      if (data) {
        showStatusModal("canceled successfully!");
      }
      fetchGroupSchedule();
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchGroupSchedule();
    setInvitationInputToggle("");
  }, []);

  return (
    <div className="groups-container">
      {loading && (
        <div className="loader-container">
          <div className="loader"></div>
        </div>
      )}
      <ModalConfirmation
        modalText={t("cancel_schedule_confirmation_modal_text")}
        modalSubText={t("cancel_schedule_confirmation_modal_subText")}
        changeModal={setConfirmModalToggle}
        modalValue={confirmModalToggle}
        confirm={modalConfirmationBtn}
      />

      {!groupsSchedule ? (
        <h1>{t("empty")}</h1>
      ) : (
        <ul className={`groups-list-contaienr`}>
          {groupsSchedule.map((item, index) => {
            return (
              <li className="groups-list-item" key={item.id}>
                <div className="groups-single-item">
                  <div className="groups-item-member-icon">
                    {item.groupSchedule === true ? <Group /> : <Single />}
                  </div>
                  <div className="groups-item-content">
                    <div className="groups-item-name">
                      <span>{item.description}</span>
                    </div>
                    <div className="groupe-item-icons">
                      <div className="groups-item-members">
                        {" "}
                        <span>{item.maxMembers - item.availableSeats}</span>
                        <span>/{item.maxMembers}</span>
                      </div>
                      <div className="groups-item-menu">
                        <DotsMenu
                          menuItems={menuItems}
                          isMenuOpen={item.showMenu}
                          toggleMenu={() => {
                            setMenuItemsParams({
                              scheduleId: item.id,
                              availableSeats: item.availableSeats,
                              price: item.price,
                              index: index,
                            });
                            handleMenutoggle(index);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};

export default ProviderGroups;
