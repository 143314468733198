import { useSearchParams, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { jwtDecode } from "jwt-decode";

import useRegistration from "../../store/RegistrationStore";
import ModalConfirmation from "../../Components/ModalConfirmation/ModalConfirmation";

import AuthInput from "../../Components/AuthInput/AuthInput";
import Button from "../../Components/Button/Button";

import hiddenIcon from "../../static/images/hidden.png";
import eyeIcon from "../../static/images/eye.png";

const InvitedSignUp = () => {
  const [params] = useSearchParams();
  const queryParams = Object.fromEntries([...params]);

  const navigate = useNavigate();
  const { t } = useTranslation();

  const ps10005000 = JSON.parse(localStorage.getItem("ps10005000"));

  const [passwordVisibilityToggle, setPasswordVisibilityToggle] =
    useState("password");
  const [confirmPasswordVisibilityToggle, setConfirmPasswordVisibilityToggle] =
    useState("password");

  const [confirmModalToggle, setConfirmModalToggle] = useState("");

  const [confirmModalText, setConfirmModalText] = useState("");

  const {
    firstName,
    lastName,
    phoneNumber,
    register_email,
    register_password,
    confirmPassword,
    changeFirstName,
    changeLastName,
    changePhoneNumber,
    changeRegister_password,
    changeConfirmPassword,
    changeRegister_email,
    registerValidation,
    changeRegisterValidation,
    registrationLoader,
    successLoader,
    FetchRegister,
  } = useRegistration();

  // const login = useLogin();

  // useEffect(() => {
  //   login.changePhoneNumber(
  //     queryParams.phoneNumber ? queryParams.phoneNumber : phoneNumber,
  //   );
  //   login.changePassword(register_password);
  // }, [register_password]);

  useEffect(() => {
    localStorage.removeItem("ps10005000");
    localStorage.removeItem("ps10004000");
  }, []);

  const registrationButton = async (e) => {
    e.preventDefault();

    if (confirmModalText.length) {
      navigate("/sessions");
      return;
    }

    await FetchRegister();
    // login.FetchAuth();
  };

  const cancelConfirmModal = () => {
    localStorage.removeItem("ps10005000");
    localStorage.removeItem("ps10004000");
    navigate("/");
  };

  useEffect(() => {
    if (queryParams.email) {
      changeRegister_email(queryParams.email);
    }
    if (queryParams.phoneNumber) {
      changePhoneNumber(queryParams.phoneNumber);
    }

    if (ps10005000) {
      const decodedToken = jwtDecode(ps10005000);

      if (decodedToken.email === queryParams.email) {
        setConfirmModalToggle("modal-confirm");
        setConfirmModalText(t("logged_in_with_email"));
      } else if (decodedToken.sub === queryParams.phoneNumber) {
        setConfirmModalToggle("modal-confirm");
        setConfirmModalText(t("logged_in_with_phone"));
      }
    }
  }, [ps10005000, navigate]);

  return (
    <div className="registration">
      <ModalConfirmation
        modalText={confirmModalText}
        changeModal={setConfirmModalToggle}
        modalValue={confirmModalToggle}
        confirm={registrationButton}
        cancel={cancelConfirmModal}
        cancelButtonText={t("logout")}
      />

      {registrationLoader ? (
        <div className="loader-container">
          <div className="loader"></div>
        </div>
      ) : null}

      {successLoader ? (
        <div className="successfully-registered">
          <h1>{t("regitration_success")}</h1>
        </div>
      ) : null}
      <h1 className="sign-up">{t("sign_up")}</h1>

      <form
        className={`registration-form`}
        onSubmit={(e) => {
          registrationButton(e);
        }}
      >
        <AuthInput
          name={"register-name"}
          type={"text"}
          autoComplete="name"
          value={firstName}
          placeholder={t("input_label_first_name")}
          changeValue={changeFirstName}
        />
        <AuthInput
          name={"register-last-name"}
          type={"text"}
          autoComplete="last name"
          value={lastName}
          placeholder={t("input_label_last_name")}
          changeValue={changeLastName}
        />
        <AuthInput
          name={"register-mobile"}
          type={"text"}
          autoComplete="tel"
          value={phoneNumber}
          queryValue={queryParams.phoneNumber}
          placeholder={t("input_label_phone")}
          changeValue={changePhoneNumber}
          disability={queryParams.phoneNumber ? true : false}
        />
        <AuthInput
          name={"register-email"}
          type={"email"}
          autoComplete="email"
          value={register_email}
          queryValue={queryParams.email}
          placeholder={t("input_label_email")}
          changeValue={changeRegister_email}
          disability={queryParams.email ? true : false}
        />
        <AuthInput
          name={"register-password"}
          type={passwordVisibilityToggle}
          autoComplete="new-password"
          value={register_password}
          placeholder={t("input_label_password")}
          changeValue={changeRegister_password}
          hiddenIcon={hiddenIcon}
          eyeIcon={eyeIcon}
          visibilityValue={passwordVisibilityToggle}
          changePasswordVisibility={setPasswordVisibilityToggle}
        />
        <AuthInput
          name={"register-confirm-password"}
          type={confirmPasswordVisibilityToggle}
          autoComplete="current-password"
          value={confirmPassword}
          placeholder={t("input_label_confirm_password")}
          changeValue={changeConfirmPassword}
          hiddenIcon={hiddenIcon}
          eyeIcon={eyeIcon}
          visibilityValue={confirmPasswordVisibilityToggle}
          changePasswordVisibility={setConfirmPasswordVisibilityToggle}
        />
        <Button text={t("sign_up")} type={"submit"} />
        <Button
          text={t("go_back")}
          type={"button"}
          clickEvent={(e) => {
            navigate("/");
            changeRegisterValidation("");
          }}
        />
        <div className="back-button"></div>
        <h3>{registerValidation}</h3>
      </form>
    </div>
  );
};

export default InvitedSignUp;
