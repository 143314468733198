import { useSearchParams, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { jwtDecode } from "jwt-decode";

import useLogin from "../../store/LoginStore";
import AuthInput from "../../Components/AuthInput/AuthInput";
import ModalConfirmation from "../../Components/ModalConfirmation/ModalConfirmation";

import hiddenIcon from "../../static/images/hidden.png";
import eyeIcon from "../../static/images/eye.png";
import LangSwitcher from "../../Components/LangSwitcher";

const InvitedSignIn = () => {
  const ps10005000 = JSON.parse(localStorage.getItem("ps10005000"));
  const [params, setPrams] = useSearchParams();
  const queryParams = Object.fromEntries([...params]);

  const navigate = useNavigate();
  const { t } = useTranslation();

  const [passwordVisibilityToggle, setPasswordVisibilityToggle] =
    useState("password");

  const [confirmModalToggle, setConfirmModalToggle] = useState("");

  const [confirmModalText, setConfirmModalText] = useState("");

  const {
    phoneNumber,
    password,
    changePassword,
    changePhoneNumber,
    loginValidation,
    loginLoader,
    FetchAuth,
  } = useLogin();

  const confirmModalBut = () => {
    navigate("/sessions");
    setConfirmModalToggle("");
  };

  const cancelModalBut = (e) => {
    e.preventDefault();

    setConfirmModalToggle("");
    localStorage.removeItem("ps10005000");
    localStorage.removeItem("ps10004000");
    navigate("/");
  };

  useEffect(() => {
    localStorage.removeItem("ps10005000");
    localStorage.removeItem("ps10004000");
  }, []);

  useEffect(() => {
    changePhoneNumber(
      queryParams.phoneNumber ? queryParams.phoneNumber : phoneNumber,
    );

    if (ps10005000) {
      const decodedToken = jwtDecode(ps10005000);
      if (decodedToken.email === queryParams.email) {
        setConfirmModalToggle("modal-confirm");
        setConfirmModalText(
          "You are logged in with this email. Do you want to continue to the main page?",
        );
      } else if (decodedToken.sub === queryParams.phoneNumber) {
        setConfirmModalToggle("modal-confirm");
        setConfirmModalText(
          "You are logged in with this phone number. Do you want to continue to the main page?",
        );
      }
    }
  }, [ps10005000, navigate]);

  const loginButton = (e) => {
    e.preventDefault();
    FetchAuth();
  };

  return (
    <>
      <ModalConfirmation
        modalText={confirmModalText}
        changeModal={setConfirmModalToggle}
        modalValue={confirmModalToggle}
        confirm={confirmModalBut}
        cancel={cancelModalBut}
        cancelButtonText={t("logout")}
      />

      <div className="main-container">
        {loginLoader ? (
          <div className="loader-container">
            <div className="loader"></div>
          </div>
        ) : null}
        <div className="main-title">
          <h1
            style={{
              fontSize: "1.5em",
              textTransform: "uppercase",
            }}
          >
            skedy
          </h1>
          <span>{t("enjoy")}</span>
        </div>

        <div className="bouncing-ball">
          <img
            src="https://media3.giphy.com/media/v1.Y2lkPTc5MGI3NjExdW9kbHliY2xybTJ1MGU4bGZudnRmb3NoaGs4dXlndTRia3k3ZXY4aSZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/3o6j8wb3IYcVKKJbws/giphy.gif"
            alt=""
          />
        </div>

        <div className="main-contant">
          <form
            className="login-form"
            onSubmit={(e) => {
              loginButton(e);
            }}
          >
            <AuthInput
              name={"auth-mobile"}
              type={"text"}
              autoComplete="tel"
              value={phoneNumber}
              queryValue={queryParams.phoneNumber}
              placeholder={t("input_label_phone")}
              changeValue={changePhoneNumber}
              disability={queryParams.phoneNumber ? true : false}
            />
            <AuthInput
              name={"auth-password"}
              type={passwordVisibilityToggle}
              autoComplete="current-password"
              value={password}
              placeholder={t("input_label_password")}
              changeValue={changePassword}
              hiddenIcon={hiddenIcon}
              eyeIcon={eyeIcon}
              changePasswordVisibility={setPasswordVisibilityToggle}
            />
            <button className="login-button" type="submit">
              {t("login")}
            </button>
            <h3>{loginValidation}</h3>
          </form>
          <div
            className="reset-password"
            onClick={(e) => {
              navigate("/reset");
            }}
          >
            <span>{t("forgot_password")}</span>
          </div>

          <div className="tennis-court">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="100"
              height="2"
              viewBox="0 0 100 2"
              fill="none"
            >
              <path d="M0 1H100" stroke="#B6C700" />
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="150"
              height="50"
              viewBox="0 0 150 50"
              fill="none"
            >
              <path d="M25 25H125" stroke="#B6C700" />
              <rect x="0.5" y="0.5" width="149" height="49" stroke="#B6C700" />
              <rect x="0.5" y="5.5" width="149" height="39" stroke="#B6C700" />
              <rect x="25.5" y="5.5" width="99" height="39" stroke="#B6C700" />
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="100"
              height="2"
              viewBox="0 0 100 2"
              fill="none"
            >
              <path d="M0 1H100" stroke="#B6C700" />
            </svg>
          </div>
          <div
            className={`create`}
            onClick={(e) => {
              navigate("/role-selector");
            }}
          >
            <span>{t("create_account")}</span>
          </div>
          <LangSwitcher />
        </div>
      </div>
    </>
  );
};

export default InvitedSignIn;
