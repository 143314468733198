import { format, eachDayOfInterval, addDays } from "date-fns";

export const getNext30Days = (events) => {
  const start = new Date();
  const end = addDays(start, 30);
  return eachDayOfInterval({ start, end }).map((date) => ({
    dayName: format(date, "EEEE").toLocaleLowerCase(),
    mainText: format(date, "dd"),
    date: format(date, "EEE dd"),
    hasEvents: events
      ? events.some(
          (event) =>
            format(new Date(event.NewScheduledDate), "yyyy-MM-dd") ===
            format(date, "yyyy-MM-dd"),
        )
      : false,
  }));
};

export const getLast12Months = () => {
  const months = [];
  const currentDate = new Date();

  for (let i = 0; i < 12; i++) {
    const date = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() - i,
      1,
    );

    const year = date.getFullYear();
    const monthName = date.toLocaleString("default", { month: "long" });

    const item = {
      monthName,
      subText: year,
      date: `${year} ${monthName.slice(0, 3)}`,
      hasEvents: true,
    };

    months.push(item);
  }

  return months.reverse();
};
