import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import AuthInput from "../../Components/AuthInput/AuthInput";
import Button from "../../Components/Button/Button";

import useRegistration from "../../store/RegistrationStore";

import hiddenIcon from "../../static/images/hidden.png";
import eyeIcon from "../../static/images/eye.png";

import "./style.scss";
import LangSwitcher from "../../Components/LangSwitcher";

const Registration = () => {
  const [passwordVisibilityToggle, setPasswordVisibilityToggle] =
    useState("password");
  const [confirmPasswordVisibilityToggle, setConfirmPasswordVisibilityToggle] =
    useState("password");

  const navigate = useNavigate();
  const { t } = useTranslation();

  const ps10005000 = JSON.parse(localStorage.getItem("ps10005000"));

  const {
    firstName,
    lastName,
    phoneNumber,
    register_password,
    confirmPassword,
    register_email,
    providerRegistrationSteps,

    changeFirstName,
    changeLastName,
    changePhoneNumber,
    changeRegister_password,
    changeConfirmPassword,
    changeRegister_email,
    registerValidation,
    changeRegisterValidation,
    changeProviderRegistrationSteps,

    registrationLoader,
    successLoader,
    FetchRegister,
  } = useRegistration();

  useEffect(() => {
    if (ps10005000) {
      navigate("/sessions");
      return;
    }
  }, [ps10005000, navigate]);

  useEffect(() => {
    localStorage.removeItem("ps10005000");
    localStorage.removeItem("ps10004000");
  }, []);

  const registrationButton = async (e) => {
    e.preventDefault();
    FetchRegister();
  };

  return (
    <div className="registration">
      {registrationLoader ? (
        <div className="loader-container">
          <div className="loader"></div>
        </div>
      ) : null}

      {successLoader ? (
        <div className="successfully-registered">
          <h1>{t("regitration_success")}</h1>
        </div>
      ) : null}
      <h1 className="sign-up">{t("sign_up")}</h1>

      <form
        className={`registration-form`}
        onSubmit={(e) => {
          registrationButton(e);
        }}
      >
        <AuthInput
          name={"register-name"}
          type={"text"}
          autoComplete="name"
          value={firstName}
          placeholder={t("input_label_first_name")}
          changeValue={changeFirstName}
        />
        <AuthInput
          name={"register-last-name"}
          type={"text"}
          autoComplete="last name"
          value={lastName}
          placeholder={t("input_label_last_name")}
          changeValue={changeLastName}
        />
        <AuthInput
          name={"register-mobile"}
          type={"text"}
          autoComplete="tel"
          value={phoneNumber}
          placeholder={t("input_label_phone")}
          changeValue={changePhoneNumber}
        />
        <AuthInput
          name={"register-email"}
          type={"email"}
          autoComplete="email"
          value={register_email}
          placeholder={t("input_label_email")}
          changeValue={changeRegister_email}
        />
        <AuthInput
          name={"register-password"}
          type={passwordVisibilityToggle}
          autoComplete="new-password"
          value={register_password}
          placeholder={t("input_label_password")}
          changeValue={changeRegister_password}
          hiddenIcon={hiddenIcon}
          eyeIcon={eyeIcon}
          visibilityValue={passwordVisibilityToggle}
          changePasswordVisibility={setPasswordVisibilityToggle}
        />
        <AuthInput
          name={"register-confirm-password"}
          type={confirmPasswordVisibilityToggle}
          autoComplete="current-password"
          value={confirmPassword}
          placeholder={t("input_label_confirm_password")}
          changeValue={changeConfirmPassword}
          hiddenIcon={hiddenIcon}
          eyeIcon={eyeIcon}
          visibilityValue={confirmPasswordVisibilityToggle}
          changePasswordVisibility={setConfirmPasswordVisibilityToggle}
        />
        <Button text={t("sign_up")} type={"submit"} />
        <Button
          text={t("go_back")}
          type="button"
          clickEvent={(e) => {
            changeRegisterValidation("");

            if (providerRegistrationSteps === 2) {
              changeProviderRegistrationSteps(1);
              return;
            }
            navigate(-1);
          }}
        />
        <LangSwitcher />
        <h3>{registerValidation}</h3>
      </form>
    </div>
  );
};

export default Registration;
