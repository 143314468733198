import "./style.scss";

const AuthInput = ({
  type,
  value,
  queryValue,
  placeholder,
  changeValue,
  autoComplete,
  name,
  eyeIcon,
  hiddenIcon,
  changePasswordVisibility,
  disability,
}) => {
  return (
    <div className={`auth-input-container ${type}`}>
      <input
        name={name}
        disabled={disability}
        id={name}
        className="input"
        type={type}
        autoComplete={autoComplete}
        value={disability === false ? value : queryValue}
        placeholder={placeholder}
        onChange={(e) => {
          changeValue(e.target.value);
        }}
      />
      <img
        src={hiddenIcon}
        alt=""
        id={"hidden"}
        onClick={() => {
          changePasswordVisibility("text");
        }}
      />
      <img
        src={eyeIcon}
        alt=""
        id={"eye"}
        onClick={() => {
          changePasswordVisibility("password");
        }}
      />
    </div>
  );
};

export default AuthInput;
