import { Routes, Route, useLocation } from "react-router-dom";
import { jwtDecode } from "jwt-decode";

import PrivateRoutes from "./PrivateRoutes";

import NotFound from "../Pages/NotFound/NotFound";
import Main from "../Pages/Main/Main";
import Registration from "../Pages/Registration/Registration";
import ScheduleProvider from "../Pages/ScheduleProvider";
import ScheduleConsumer from "../Pages/ScheduleConsumer/ScheduleConsumer";
import Bills from "../Pages/Bills";
import Customres from "../Pages/Customers/Customers";
import ScheduleCreateInvite from "../Pages/ScheduleCreateInvite/ScheduleCreateInvite";
import ScheduleEdit from "../Pages/ScheduleEdit";
import Invitation from "../Pages/Invitation/Invitation";
import ScheduleDetails from "../Pages/ScheduleDetails/ScheduleDetails";
import SessionDetails from "../Pages/SessionDetails/SessionDetails";
import InvitedSignIn from "../Pages/InvitedSignIn/InvitedSignIn";
import InvitedSignUp from "../Pages/InvitedSignUp/InvitedSignUp";
import Reschedule from "../Pages/Reschedule";
import Profile from "../Pages/Profile/Profile";
import ProviderGroups from "../Pages/ProviderGroups";
import UserRoleSelector from "../Pages/UserRoleSelector/UserRoleSelector";
import ProviderRegistration from "../Pages/ProviderRegistration/ProviderRegistration";
import ProviderHistory from "../Pages/ProviderHistory/ProviderHistory";
import ConsumerHistory from "../Pages/ConsumerHistory/ConsumerHistory";

const Router = () => {
  const ps10005000 = JSON.parse(localStorage.getItem("ps10005000"));
  const { pasthname } = useLocation();

  const getUserRole = (token) => {
    if (token) {
      const encodedToken = jwtDecode(token);
      return encodedToken.roles;
    } else {
      return null;
    }
  };

  return (
    <Routes>
      <Route path="/" element={<Main />} />

      <Route path="/reset" element={<Main />} />
      <Route path="/reset-password" element={<Main />} />

      <Route path="/role-selector" element={<UserRoleSelector />} />
      <Route
        path="/role-selector/consumer-registration"
        element={<Registration />}
      />
      <Route
        path="/role-selector/provider-registration"
        element={<ProviderRegistration />}
      />

      <Route path="/signIn" element={<InvitedSignIn />} />
      <Route path="/signUp" element={<InvitedSignUp />} />
      <Route path="/usedInv" element={<Main />} />

      <Route path="*" element={<NotFound />} />

      <Route element={<PrivateRoutes />}>
        <Route path="/profile" element={<Profile />} />
        <Route
          path="/sessions"
          element={
            getUserRole(ps10005000) === "Consumer" ? (
              <ScheduleConsumer />
            ) : (
              <ScheduleProvider />
            )
          }
        />
        <Route
          path="/schedules"
          element={
            getUserRole(ps10005000) === "Provider" ? (
              <ProviderGroups />
            ) : (
              <NotFound />
            )
          }
        />
        <Route path="/bills" element={<Bills />} />
        <Route path="/customers" element={<Customres />} />
        <Route
          path="/invite"
          element={
            getUserRole(ps10005000) === "Provider" ? (
              <ScheduleCreateInvite />
            ) : (
              <NotFound />
            )
          }
        />
        <Route
          path="/invitation"
          element={
            getUserRole(ps10005000) === "Consumer" ? (
              <Invitation />
            ) : (
              <NotFound />
            )
          }
        />
        <Route
          path="/schedule/:scheduleID"
          element={
            getUserRole(ps10005000) === "Provider" ? (
              <ScheduleDetails />
            ) : (
              <NotFound />
            )
          }
        />
        <Route
          path="/schedule/edit/:scheduleId"
          element={
            getUserRole(ps10005000) === "Provider" ? (
              <ScheduleEdit />
            ) : (
              <NotFound />
            )
          }
        />
        <Route
          path="/session/:sessionID"
          element={
            getUserRole(ps10005000) === "Provider" ? (
              <SessionDetails />
            ) : (
              <NotFound />
            )
          }
        />
        <Route
          path="/reschedule/:scheduleID"
          element={
            getUserRole(ps10005000) === "Provider" ? (
              <Reschedule />
            ) : (
              <NotFound />
            )
          }
        />
        <Route
          path="/history"
          element={
            getUserRole(ps10005000) === "Provider" ? (
              <ProviderHistory />
            ) : (
              <ConsumerHistory />
            )
          }
        />
      </Route>
    </Routes>
  );
};

export default Router;
