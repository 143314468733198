import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useTranslation } from "react-i18next";

import { jwtDecode } from "jwt-decode";

import useApi from "../../hooks/useApi";

import PhoneNumberToDial from "../../Components/PhoneNumberToDial";
import ModalConfirmation from "../../Components/ModalConfirmation/ModalConfirmation";
import ModalWarning from "../../Components/ModalWarning/ModalWarning";
import ScheduleInviteInput from "../../Components/ScheduleInviteInput/ScheduleInviteInput";

import { ReactComponent as Tennis } from "../../static/images/tennis.svg";
import { ReactComponent as Boxing } from "../../static/images/boxing.svg";
import { ReactComponent as Yoga } from "../../static/images/yoga.svg";
import { ReactComponent as Soccer } from "../../static/images/soccer.svg";
import { ReactComponent as Bascketball } from "../../static/images/basketball.svg";
import { ReactComponent as Dance } from "../../static/images/dance.svg";
import { ReactComponent as PersonalTrainer } from "../../static/images/presonal_trainer.svg";
import { ReactComponent as Tick } from "../../static/images/tick_sign.svg";

import "./style.scss";

const Invitation = () => {
  const { put, get, loading } = useApi();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [invitationData, setInvitationData] = useState([]);

  const [confirmModalToggle, setConfirmModalToggle] = useState("");
  const [warningModalToggle, setWarningModalToggle] = useState("");
  const [modalText, setModalText] = useState("");
  const [singleInv, setSingleInv] = useState({});

  const [monday, setMonday] = useState("");
  const [tuesday, setTuesday] = useState("");
  const [wednesday, setWednesday] = useState("");
  const [thursday, setThursday] = useState("");
  const [friday, setFriday] = useState("");
  const [saturday, setSaturday] = useState("");
  const [sunday, setSunday] = useState("");

  const [inputNameValue, setInputNameValue] = useState("");

  const fetchInvitation = async () => {
    const ps10005000 = JSON.parse(localStorage.getItem("ps10005000"));

    if (ps10005000) {
      const encodedToken = jwtDecode(ps10005000);

      try {
        const data = await get(
          `/Invitation?PhoneNumber=${encodedToken.sub}&Email=${encodeURIComponent(encodedToken.email)}&Status=Pending`,
        );

        checkDetailsDays(data);

        setInvitationData(data);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const RenderActivities = ({ activities }) => {
    const activityIcons = {
      tennis: <Tennis />,
      boxing: <Boxing />,
      yoga: <Yoga />,
      soccer: <Soccer />,
      bascketball: <Bascketball />,
      dance: <Dance />,
      "Personal Trainer": <PersonalTrainer />,
    };

    return <>{activityIcons[activities] || ""}</>;
  };

  const checkDetailsDays = (detailsData) => {
    detailsData.forEach((item) => {
      item.scheduleDays.forEach((day) => {
        if (day.dayOfWeek === "Monday") {
          setMonday(["active", day.timeOnly.slice(0, 5), item.id]);
        }
        if (day.dayOfWeek === "Tuesday") {
          setTuesday(["active", day.timeOnly.slice(0, 5), item.id]);
        }
        if (day.dayOfWeek === "Wednesday") {
          setWednesday(["active", day.timeOnly.slice(0, 5), item.id]);
        }
        if (day.dayOfWeek === "Thursday") {
          setThursday(["active", day.timeOnly.slice(0, 5), item.id]);
        }
        if (day.dayOfWeek === "Friday") {
          setFriday(["active", day.timeOnly.slice(0, 5), item.id]);
        }
        if (day.dayOfWeek === "Saturday") {
          setSaturday(["active", day.timeOnly.slice(0, 5), item.id]);
        }
        if (day.dayOfWeek === "Sunday") {
          setSunday(["active", day.timeOnly.slice(0, 5), item.id]);
        }
      });
    });
  };

  const cancelInvitation = async () => {
    try {
      const data = await put(`/Invitation`, {
        id: singleInv.id,
        operation: "Cancel",
      });
      await fetchInvitation();

      setModalText("thank you!");
      setWarningModalToggle("modal-warning");
    } catch (error) {
      console.log(error);
    }
  };

  const activateInvitation = async () => {
    try {
      const data = await put(`/Invitation`, {
        id: singleInv.id,
        operation: "Activate",
        subscriptionName: inputNameValue,
      });

      await fetchInvitation();

      setModalText("Subscription is activated successfully.\nThank you!");
      setWarningModalToggle("modal-warning");
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    try {
      fetchInvitation();
    } catch (err) {
      console.error("Error:", err);
    }
  }, []);

  return (
    <div className="invitation-container">
      <ModalConfirmation
        changeModal={setConfirmModalToggle}
        modalValue={confirmModalToggle}
        modalText={modalText}
        confirm={
          modalText.includes("cancel") ? cancelInvitation : activateInvitation
        }
        inputModal={
          modalText.includes("cancel") ? null : (
            <ScheduleInviteInput
              label={"Rename your subscription"}
              value={inputNameValue}
              changeValue={setInputNameValue}
            />
          )
        }
      />
      <ModalWarning
        changeModal={setWarningModalToggle}
        modalValue={warningModalToggle}
        warningText={modalText}
        buttonText={invitationData.length < 1 ? "Go to main page" : "Close"}
        Image={Tick}
      />

      {invitationData.length ? (
        <ul className="invitation-container-ul">
          {invitationData.map((item) => {
            return (
              <li key={item.id} className="invitation-container-li">
                <div className="invitation-list-container">
                  <div className="invitation-from">
                    <span>{t("invitation_from")}</span>
                  </div>
                  <div className="invitation-name">
                    <div className="activity-icon">
                      <RenderActivities activities={item.activityName} />
                    </div>
                    <h2>{item.providerName}</h2>
                  </div>

                  <div className="provider-phone">
                    <PhoneNumberToDial number={item.providerPhoneNumber} />
                  </div>

                  <div className="details-days">
                    <div
                      className={
                        item.scheduleDays[0].dayOfWeek && item.id === monday[2]
                          ? monday[0]
                          : ""
                      }
                    >
                      <span>mo</span>{" "}
                      <span>
                        {item.scheduleDays[0].dayOfWeek && item.id === monday[2]
                          ? monday[1]
                          : ""}
                      </span>
                    </div>
                    <div
                      className={
                        item.scheduleDays[0].dayOfWeek && item.id === tuesday[2]
                          ? tuesday[0]
                          : ""
                      }
                    >
                      {" "}
                      <span>tu</span>{" "}
                      <span>
                        {" "}
                        {item.scheduleDays[0].dayOfWeek &&
                        item.id === tuesday[2]
                          ? tuesday[1]
                          : ""}
                      </span>
                    </div>
                    <div
                      className={
                        item.scheduleDays[0].dayOfWeek &&
                        item.id === wednesday[2]
                          ? wednesday[0]
                          : ""
                      }
                    >
                      {" "}
                      <span>we</span>{" "}
                      <span>
                        {item.scheduleDays[0].dayOfWeek &&
                        item.id === wednesday[2]
                          ? wednesday[1]
                          : ""}
                      </span>
                    </div>
                    <div
                      className={
                        item.scheduleDays[0].dayOfWeek &&
                        item.id === thursday[2]
                          ? thursday[0]
                          : ""
                      }
                    >
                      {" "}
                      <span>th</span>{" "}
                      <span>
                        {item.scheduleDays[0].dayOfWeek &&
                        item.id === thursday[2]
                          ? thursday[1]
                          : ""}
                      </span>
                    </div>
                    <div
                      className={
                        item.scheduleDays[0].dayOfWeek && item.id === friday[2]
                          ? friday[0]
                          : ""
                      }
                    >
                      {" "}
                      <span>fr</span>{" "}
                      <span>
                        {item.scheduleDays[0].dayOfWeek && item.id === friday[2]
                          ? friday[1]
                          : ""}
                      </span>
                    </div>
                    <div
                      className={
                        item.scheduleDays[0].dayOfWeek &&
                        item.id === saturday[2]
                          ? saturday[0]
                          : ""
                      }
                    >
                      {" "}
                      <span>sa</span>{" "}
                      <span>
                        {item.scheduleDays[0].dayOfWeek &&
                        item.id === saturday[2]
                          ? saturday[1]
                          : ""}
                      </span>
                    </div>
                    <div
                      className={
                        item.scheduleDays[0].dayOfWeek && item.id === sunday[2]
                          ? sunday[0]
                          : ""
                      }
                    >
                      {" "}
                      <span>su</span>{" "}
                      <span>
                        {item.scheduleDays[0].dayOfWeek && item.id === sunday[2]
                          ? sunday[1]
                          : ""}
                      </span>
                    </div>
                  </div>

                  <div className="financial-details">
                    <ul>
                      <li>{`price ${item?.financialDetails?.pricingType === "Monthly" ? "per month" : "per session"} is ${item?.financialDetails.price} GEL`}</li>
                      <li>{`you will be asked to pay full amount ${
                        item?.financialDetails?.billingPeriod ===
                          "Based_On_Session_Events" &&
                        item?.financialDetails?.paymentType === "PostPaid"
                          ? `of ${item?.financialDetails?.billingEventCount} sessions by the 5th of the next month`
                          : item?.financialDetails?.billingPeriod ===
                                "Based_On_Session_Events" &&
                              item?.financialDetails?.paymentType === "PrePaid"
                            ? `of ${item?.financialDetails?.billingEventCount} sessions in advance after ${item?.financialDetails?.prepaymentEventCount} passed sessions`
                            : item?.financialDetails?.billingPeriod ===
                                "End_Of_The_Month"
                              ? "for passed sessions by the 5th of next month"
                              : "by the 5th of the month"
                      }`}</li>
                    </ul>
                  </div>

                  <div className="invitation-buttons">
                    <div id="invitation-accept">
                      <button
                        onClick={(e) => {
                          setConfirmModalToggle("modal-confirm");
                          setModalText("activate subscription?");
                          setSingleInv(item);
                          setInputNameValue(item.providerName);
                        }}
                      >
                        {t("accept")}
                      </button>
                    </div>
                    <div id="invitation-reject">
                      <button
                        onClick={(e) => {
                          setConfirmModalToggle("modal-confirm");
                          setModalText("cancel subscription?");
                          setSingleInv(item);
                        }}
                      >
                        {t("reject")}
                      </button>
                    </div>
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      ) : (
        <div className="empty-invitation">
          <h2> {t("invitation_empty")}</h2>
        </div>
      )}
      {loading && (
        <div className="loader-container">
          <div className="loader"></div>
        </div>
      )}
    </div>
  );
};

export default Invitation;
