import { useEffect, useState } from "react";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { jwtDecode } from "jwt-decode";

import useApi from "../../hooks/useApi";

import useLogin from "../../store/LoginStore";
import AuthInput from "../../Components/AuthInput/AuthInput";

import ModalWarning from "../../Components/ModalWarning/ModalWarning";
import ModalConfirmation from "../../Components/ModalConfirmation/ModalConfirmation";
import LangSwitcher from "../../Components/LangSwitcher";

import hiddenIcon from "../../static/images/hidden.png";
import eyeIcon from "../../static/images/eye.png";

import { ReactComponent as Tick } from "../../static/images/tick_sign.svg";
import { ReactComponent as Exclamation } from "../../static/images/exclamation_sign.svg";

import "./style.scss";

const Main = () => {
  const [resetPassInput, setResetPassInput] = useState("");
  const [resetPassword, setResetPassword] = useState("");
  const [resetConfirmPassword, setResetConfirmPassword] = useState("");

  const [resetPasswordVisibilityToggle, setResetPasswordVisibilityToggle] =
    useState("password");
  const [
    confirmResetPasswordVisibilityToggle,
    setConfirmResetPasswordVisibilityToggle,
  ] = useState("password");

  const [warningModalToggle, setWarningModalToggle] = useState("");
  const [warningModalText, setWarningModalText] = useState("");
  const [warningButtonText, setWarningButtonText] = useState("");

  const [confirmModalToggle, setConfirmModalToggle] = useState("");

  const [confirmModalText, setConfirmModalText] = useState("");

  const [resetPasswordValidation, setResetPasswordValidation] = useState("");

  const navigate = useNavigate();
  const { t } = useTranslation();

  const { pathname } = useLocation();
  const [params] = useSearchParams();
  const queryParams = Object.fromEntries([...params]);

  const { get, post } = useApi();

  const ps10005000 = JSON.parse(localStorage.getItem("ps10005000"));
  const [passwordVisibilityToggle, setPasswordVisibilityToggle] =
    useState("password");

  const {
    password,
    phoneNumber,
    changePassword,
    changePhoneNumber,
    loginValidation,
    loginLoader,
    FetchAuth,
  } = useLogin();

  // useEffect(() => {
  //   localStorage.removeItem("ps10005000");
  //   localStorage.removeItem("ps10004000");
  // }, []);

  const sendLoginLink = async (e) => {
    e.preventDefault();

    fetch(
      `${process.env.REACT_APP_BASE_URL}/User/reset-password?Identifier=${resetPassInput}`,
    ).then((response) => {
      if (response.status === 200) {
        setWarningModalToggle("modal-warning");
        setWarningModalText(t("reset_link_sent"));
        setWarningButtonText(t("go_login_page"));
      } else {
        setWarningModalToggle("modal-warning");
        setWarningModalText(t("error_account_not_found"));
        setWarningButtonText(t("close"));
      }
    });
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();

    let upperCaseLetters = /[A-Z]/g;
    let number = /[0-9]/g;
    let specialChars = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;

    if (resetPassword === "" || resetConfirmPassword === "") {
      setResetPasswordValidation(t("error_fill_all"));
      return;
    }

    if (resetPassword !== resetConfirmPassword) {
      setResetPasswordValidation(t("error_password_confirm_failed"));
      return;
    }

    if (!resetPassword.match(upperCaseLetters)) {
      setResetPasswordValidation(t("error_password_contains_uppercase"));
      return;
    }
    if (!resetPassword.match(specialChars)) {
      setResetPasswordValidation(t("error_password_contains_special"));
      return;
    }
    if (!resetPassword.match(number)) {
      setResetPasswordValidation(t("error_password_contains_digit"));
      return;
    }
    if (resetPassword.length < 8) {
      setResetPasswordValidation(t("error_password_length"));
      return;
    }

    try {
      const data = await post("/User/reset-password-confirmation", {
        id: queryParams.user,
        password: resetPassword,
        confirmPassword: resetConfirmPassword,
        token: queryParams.token,
      });

      if (data.succeeded === true) {
        setWarningModalToggle("modal-warning");
        setWarningModalText(t("password_reset_success"));
        setWarningButtonText(t("go_login_page"));
      } else {
        setWarningModalToggle("modal-warning");
        setWarningModalText(data.errors[0].description);
        setWarningButtonText(t("close"));
      }
    } catch (err) {}
  };

  const confirmModalBtn = () => {
    navigate("/sessions");
    setConfirmModalToggle("");
  };

  const cancelModalBtn = (e) => {
    e.preventDefault();

    setConfirmModalToggle("");
    localStorage.removeItem("ps10005000");
    localStorage.removeItem("ps10004000");
    navigate("/");
  };

  const loginButton = async (e) => {
    e.preventDefault();
    FetchAuth();
  };

  useEffect(() => {
    if (ps10005000 && pathname === "/") {
      navigate("sessions");
      return;
    }

    if (pathname.includes("/usedInv")) {
      if (ps10005000) {
        const decodedToken = jwtDecode(ps10005000);
        if (decodedToken.email === queryParams.email) {
          setConfirmModalToggle("modal-confirm");
          setConfirmModalText(t("logged_in_with_email"));
        } else if (decodedToken.sub === queryParams.phoneNumber) {
          setConfirmModalToggle("modal-confirm");
          setConfirmModalText(t("logged_in_with_phone"));
        }
      } else {
        setWarningModalToggle("modal-warning");
        setWarningModalText(t("invitation_expired"));
        setWarningButtonText(t("close"));
      }
    }
  }, [ps10005000, navigate]);

  return (
    <>
      <ModalWarning
        warningText={warningModalText}
        changeModal={setWarningModalToggle}
        modalValue={warningModalToggle}
        buttonText={warningButtonText}
        Image={warningButtonText === t("close") ? Exclamation : Tick}
      />
      <ModalConfirmation
        modalText={confirmModalText}
        changeModal={setConfirmModalToggle}
        modalValue={confirmModalToggle}
        confirm={confirmModalBtn}
        cancel={cancelModalBtn}
        cancelButtonText={t("logout")}
      />

      <div className="main-container">
        {loginLoader ? (
          <div className="loader-container">
            <div className="loader"></div>
          </div>
        ) : null}
        <div className="main-title">
          <h1
            style={{
              fontSize: "1.5em",
              textTransform: "uppercase",
            }}
          >
            skedy
          </h1>
          <span>{t("enjoy")}</span>
        </div>

        {pathname === "/reset" || pathname === "/reset-password" ? null : (
          <div className="bouncing-ball">
            <img
              src="https://media3.giphy.com/media/v1.Y2lkPTc5MGI3NjExdW9kbHliY2xybTJ1MGU4bGZudnRmb3NoaGs4dXlndTRia3k3ZXY4aSZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/3o6j8wb3IYcVKKJbws/giphy.gif"
              alt=""
            />
          </div>
        )}

        <div className="main-contant">
          {pathname === "/reset-password" ? (
            <>
              <div className="reset-header-text">
                <h3>{t("password_create")}</h3>
              </div>
              <div className="reset-text">
                <span>{t("password_recomendations")}</span>
              </div>
              <form
                onSubmit={(e) => {
                  handleResetPassword(e);
                }}
                className="reset-password-form"
              >
                <AuthInput
                  name={"register-password"}
                  type={resetPasswordVisibilityToggle}
                  value={resetPassword}
                  placeholder={t("input_label_new_password")}
                  changeValue={setResetPassword}
                  hiddenIcon={hiddenIcon}
                  eyeIcon={eyeIcon}
                  visibilityValue={resetPasswordVisibilityToggle}
                  changePasswordVisibility={setResetPasswordVisibilityToggle}
                />
                <AuthInput
                  name={"register-confirm-password"}
                  type={confirmResetPasswordVisibilityToggle}
                  value={resetConfirmPassword}
                  placeholder={t("input_label_new_password_confirm")}
                  changeValue={setResetConfirmPassword}
                  hiddenIcon={hiddenIcon}
                  eyeIcon={eyeIcon}
                  visibilityValue={confirmResetPasswordVisibilityToggle}
                  changePasswordVisibility={
                    setConfirmResetPasswordVisibilityToggle
                  }
                />
                <button
                  className="reset-button"
                  style={{
                    marginBottom: "10px",
                    opacity:
                      resetPassword === "" || resetConfirmPassword === ""
                        ? 0.5
                        : 1,
                  }}
                >
                  {t("reset_password")}
                </button>
                <h3>{resetPasswordValidation}</h3>
              </form>
            </>
          ) : null}

          {pathname === "/reset" ? (
            <>
              <div className="reset-text">
                <span>{t("reset_password_info")}</span>
              </div>
              <form
                onSubmit={(e) => {
                  sendLoginLink(e);
                }}
              >
                <AuthInput
                  name={"reset"}
                  type={"text"}
                  value={resetPassInput}
                  placeholder={t("input_label_phone_or_email")}
                  changeValue={setResetPassInput}
                />

                <button className="reset-button">{t("send_login_link")}</button>
              </form>
              <div
                className="back-to-login"
                onClick={() => {
                  navigate("/");
                }}
              >
                <span>{t("go_login_page")}</span>
              </div>
            </>
          ) : (
            <>
              {pathname === "/reset-password" ? null : (
                <>
                  <form
                    className="login-form"
                    onSubmit={(e) => {
                      loginButton(e);
                    }}
                  >
                    <AuthInput
                      name={"auth-mobile"}
                      type={"text"}
                      autoComplete="tel"
                      value={phoneNumber}
                      placeholder={t("input_label_phone")}
                      changeValue={changePhoneNumber}
                    />
                    <AuthInput
                      name={"auth-password"}
                      type={passwordVisibilityToggle}
                      autoComplete="current-password"
                      value={password}
                      placeholder={t("input_label_password")}
                      changeValue={changePassword}
                      hiddenIcon={hiddenIcon}
                      eyeIcon={eyeIcon}
                      changePasswordVisibility={setPasswordVisibilityToggle}
                    />
                    <button className="login-button" type="submit">
                      {t("login")}
                    </button>
                    <h3>{loginValidation}</h3>
                  </form>
                  <div
                    className="reset-password"
                    onClick={() => {
                      navigate("/reset");
                    }}
                  >
                    <span>{t("forgot_password")}</span>
                  </div>
                </>
              )}
            </>
          )}

          <div className="tennis-court">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="100"
              height="2"
              viewBox="0 0 100 2"
              fill="none"
            >
              <path d="M0 1H100" stroke="#B6C700" />
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="150"
              height="50"
              viewBox="0 0 150 50"
              fill="none"
            >
              <path d="M25 25H125" stroke="#B6C700" />
              <rect x="0.5" y="0.5" width="149" height="49" stroke="#B6C700" />
              <rect x="0.5" y="5.5" width="149" height="39" stroke="#B6C700" />
              <rect x="25.5" y="5.5" width="99" height="39" stroke="#B6C700" />
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="100"
              height="2"
              viewBox="0 0 100 2"
              fill="none"
            >
              <path d="M0 1H100" stroke="#B6C700" />
            </svg>
          </div>

          {pathname.includes("/reset") ? null : (
            <div
              className={`create`}
              onClick={(e) => {
                navigate("/role-selector");
              }}
            >
              <span>{t("create_account")}</span>
            </div>
          )}
          <LangSwitcher />
        </div>
      </div>
    </>
  );
};

export default Main;
